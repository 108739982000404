<app-navigation
  class="pcoded-navbar"
  [ngClass]="{
    'navbar-collapsed': navCollapsed,
    'mob-open': navCollapsedMob}"
  (NavCollapse)="this.navCollapsed = !this.navCollapsed"
  (NavCollapsedMob)="navMobClick()">
</app-navigation>
<app-nav-bar
  class="navbar pcoded-header navbar-expand-lg navbar-light"
  (NavCollapsedMob)="navMobClick()">
</app-nav-bar>
<div class="pcoded-main-container">
  <div class="pcoded-wrapper">
    <div class="pcoded-content">
      <div class="pcoded-inner-content">
        <!--        <app-breadcrumb></app-breadcrumb>-->
        <div class="main-body">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>


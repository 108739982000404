import {ModuleWithProviders, NgModule} from '@angular/core';
import {NbThemeModule} from '@nebular/theme';
import {CoreModule} from '@shared/core.module';


@NgModule({
    imports: [CoreModule],
})
export class ThemeModule {
    static forRoot(): ModuleWithProviders<ThemeModule> {
        return {
            ngModule: ThemeModule,
            providers: [
                ...NbThemeModule.forRoot(
                    {
                        name: 'default',
                    },
                    [],
                ).providers || [],
            ],
        };
    }
}

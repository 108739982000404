import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Subject} from 'rxjs';
import {ResponseModel} from "@models/response.model";
import {UserModel} from "@models/user.model";

@Injectable({providedIn: 'root'})
export class UserService {
  private update = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  getAll(page = 0,
         perPage = 5,
         sort: string | null = null,
         filterKeyword: string | null = null,
         role: any | null = null
  ) {
    let params = new HttpParams()
      .set('page', page)
      .set('per_page', perPage);
    if (sort !== null) {
      params = params.set('sort', sort);
    }
    if (role !== null) {
      params = params.set('filter[role]', role);
    }


    if (filterKeyword !== null) {
      params = params.set('filter[search]', filterKeyword);
    }
    return this.http.get<ResponseModel<any>>('admin/users', {params});
  }

  addUser(user: any) {
    return this.http.post<ResponseModel<UserModel>>('admin/users', user);
  }

  getUserByID(id: any) {
    return this.http.get<ResponseModel<UserModel>>(`admin/users/${id}`);
  }

  editUser(userId: any, user: any) {
    return this.http.post<ResponseModel<UserModel>>(`admin/users/${userId}?_method=PUT`, user);
  }

  deleteUser(id: string) {
    return this.http.delete(`admin/users/${id}`);
  }


  sendUpdate(message: string) {
    this.update.next(message);
  }

  getUpdate() {
    return this.update.asObservable();
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['search.component.scss'],
})
export class SearchComponent {
  @Output() searchEvent = new EventEmitter<string>();
  @Output() reloadEvent = new EventEmitter<string>();
  @Input() placeholder = '';
  @Input() filterKeyword: string | null = '';
  @Input() always = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private location: Location,
  ) {
    if (!this.placeholder) {
      this.placeholder = 'search';
    }
  }

  search() {
    if (this.always) {
      return;
    }
    this.filterKeyword = this.filterKeyword ? this.filterKeyword : null;
    this.emitKeyword();
  }

  change() {
    if (!this.always) {
      return;
    }
    this.filterKeyword = this.filterKeyword ? this.filterKeyword : null;
    this.emitKeyword();
  }

  emitKeyword() {
    this.searchEvent.emit(this.filterKeyword!);
  }

  private updateRouteParameters() {
    const params = {
      'search-key': this.filterKeyword,
    };
    const urlTree = this.router.createUrlTree([location.pathname], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge',
    });

    this.location.go(urlTree.toString());
  }
}

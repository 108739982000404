<header>
  <div class="m-header" [style.display]="this.headerStyle">
    <a class="px-1"
       id="mobile-collapse1"
       href="javascript:"
       (click)="this.NavCollapsedMob.emit()">
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 72 72"
           style="fill:#FFFFFF;">
        <path
            d="M56 48c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 48 54.798 48 56 48zM56 32c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 32 54.798 32 56 32zM56 16c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 16 54.798 16 56 16z"></path>
      </svg>
    </a>
    <div class="col mx-2">
      <app-notifications></app-notifications>
    </div>
    <ul class="navbar-nav">
      <li>
        <div class="dropdown drp-user" ngbDropdown placement="auto">
          <a style="color: white" href="javascript:" ngbDropdownToggle data-toggle="dropdown">
            <i class="icon feather icon-settings"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-end profile-notification"
               ngbDropdownMenu>
            <ul class="pro-body">
              <li>
                <a (click)="appVersion()" class="dropdown-item"><i class="feather icon-share"></i>App version</a>
              </li>
              <li>
                <a (click)="logout()" class="dropdown-item"><i class="feather icon-log-in"></i> Log out</a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>


  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
    <app-nav-left
        class="me-auto"
        [style.display]="this.headerStyle"
    ></app-nav-left>
    <app-nav-right class="ms-auto"></app-nav-right>
  </div>
</header>

export class HttpErrorModel {
    message: string;
    errors: Array<string>;
    statusCode: number;

    constructor(message: string,
        errors: Array<string>,
        statusCode: number) {
        this.message = message;
        this.errors = errors;
        this.statusCode = statusCode;
    }
}


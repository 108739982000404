<div class="fixed-button active">
  <a
    href="/"
    target="_blank"
    class="btn btn-md btn-theme has-ripple"
  >
    <i class="fa fa-phone" aria-hidden="true"></i>
    Call Now
  </a>
</div>

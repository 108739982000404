import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatType',
    pure: false,
})
export class TypesPipe implements PipeTransform {
    transform(value: any): string {
        if (value) {
            const removeUnderscore = value.split('_').join(' ');
            return removeUnderscore.charAt(0).toUpperCase() + removeUnderscore.slice(1).toLowerCase();
        } return '';
    }
}

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from "@env";
import {AuthService} from "@services/auth.service";

const API_URL = environment.apiUrl;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  private static appendApiUrl(request: HttpRequest<any>) {
    if (!request.url.endsWith('.json') && !request.url.endsWith('.pdf')) {
      request = request.clone({url: API_URL + request.url});
    }
    return request;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = this.authService.token$.value;

    // Append API URL
    request = TokenInterceptor.appendApiUrl(request);

    // Request not requires access token
    if (!user ||
      request.url.includes('/auth/login') ||
      request.url.includes('/auth/logout')
    ) {
      return next.handle(request);
    }

    // Handle response
    return next.handle(this.authService.addAuthHeader(request, user)).pipe(map((value) => {
        return value;
      }, catchError((error) => {
        return this.handleResponseError(error, request);
      })),
    );
  }

  handleResponseError(error: any, request: HttpRequest<any>) {
    if (request.url.includes('/auth/logout')) {
      return throwError(error);
    }

    if (error.status === 401) {
      this.authService.logout();
    }
    return throwError(error);
  }
}

//import '../../shared/css-shared/toaster.css';

import {Injectable} from '@angular/core';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig,
  NbToastrService,
} from '@nebular/theme';

@Injectable({providedIn: 'root'})

export class ToastService {
  config!: NbToastrConfig;

  index = 1;
  destroyByClick = true;
  duration = 3000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.BOTTOM_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = 'primary';

  constructor(private toastService: NbToastrService) {
  }

  showToast(type: NbComponentStatus, title: string, body: any, duration?: number) {
    const config = {
      status: type,
      enableHtml: true,
      destroyByClick: this.destroyByClick,
      duration: duration ?? this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
      toastClass: 'custom-toast'
    };

    this.toastService.show(
      body,
      title,
      config);
  }
}

<div *ngIf="addTag">
  <label *ngIf="label">{{label}}</label>
  <ng-select (change)="_handleInput()"
             (remove)="handleRemove($event)"
             [(ngModel)]="selectedItem"
             [addTag]="addTagFn"
             [bindLabel]="display"
             [bindValue]="bindValue"
             [clearSearchOnAdd]="true"
             [closeOnSelect]="!multiple"
             [compareWith]="compareCardObjects"
             [dropdownPosition]=dropdownPosition
             [hideSelected]="true"
             [items]="items"
             [loading]="searching"
             [multiple]="multiple"
             [notFoundText]="'No '+placeholder+' found'"
             [placeholder]="placeholder"
             [selectOnTab]="true"
             [typeahead]="input$"
             [virtualScroll]="true"
             typeToSearchText="Type to search">
    <ng-template let-search="searchTerm" ng-tag-tmp>
      <small>Not found. <br>Click to add: {{search}}</small>
    </ng-template>
  </ng-select>
</div>

<div *ngIf="!addTag">
  <label *ngIf="label">{{label}}</label>
  <ng-select (change)="_handleInput()"
             (remove)="handleRemove($event)"
             [(ngModel)]="selectedItem"
             [bindLabel]="display"
             [bindValue]="bindValue"
             [clearSearchOnAdd]="true"
             [closeOnSelect]="!multiple"
             [compareWith]="compareCardObjects"
             [dropdownPosition]=dropdownPosition
             [hideSelected]="true"
             [items]="items"
             [loading]="searching"
             [multiple]="multiple"
             [notFoundText]="'No '+placeholder+' found'"
             [placeholder]="placeholder"
             [selectOnTab]="true"
             [typeahead]="input$"
             [virtualScroll]="true"
             typeToSearchText="Type to search">
  </ng-select>
</div>

<div class="dropdown" dir="ltr">
<div class="header-actions">
  <div class="" ngbDropdown>
    <button class="p-0" id="profileDropdown" (click)="loadnotifications()" ngbDropdownToggle>
      <div class="position-relative">
        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style="--bs-badge-font-size: 0.6em;">
          {{notification_count}}
        </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-bell notification-icon" viewBox="0 0 16 16">
          <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
          </svg>
     </div>
    </button>
    <div aria-labelledby="profileDropdown" class="px-0 dropdown-menu" ngbDropdownMenu>
      <div class="row container bg-light p-0 m-0 mb-1 w-100">
        <div class="col">
          <h6 class="py-3">Notifications</h6>
        </div>
        <div class="col-7">
          <button *ngIf="!loading && notread" class="btn border-0 p-0 w-100 h-100 my-auto" (click)="mark_all_as_read()">
            <p class="py-3 fw-bold px-0 w-100 text-end my-auto" style="color: rgb(131, 123, 136);">Mark all as read</p>
          </button>
        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <span *ngIf="loading" class="spinner-grow spinner-grow-sm mx-3 my-auto align-middle"  style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>
      </div>
      <div *ngIf="!loading && notifications.length>0" class="text-start">
        <div *ngFor="let notification of notifications">
          <button class="btn border-0 p-0 m-0 w-100" [ngClass]="{'bg-light': notification.read==false}" matTooltip="mark as read" (click)="mark_as_read(notification.id)">
            <div class="row w-100 container-fluid w-100 " >
              <div class="col-3 d-flex justify-content-center">
                <img src={{notification.data.icon}} class="my-auto" alt="" style="width: 40px; height: 40px;">
              </div>
              <div class="col-9 p-0">
                <p class="fw-bold mb-1 text-start"> {{notification.data.title}}</p>
                <p class="mb-1 text-start notification-text">{{notification.data.body}}</p>
               <p class="graytext text-start">{{formatLocalDate(notification.created_at).fromNow()}}</p>
              </div>
              <mat-divider class="w-100 my-2"></mat-divider>
           </div>
          </button>
       </div>
      </div>
      <div *ngIf="notifications.length<1" class="px-3">
          There are no new notifications
      </div>
    </div>
</div>
</div>
</div>

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Subject} from 'rxjs';
import {ResponseModel} from "@models/response.model";
import {AppointmentModel} from "@models/appointment.model";
import {PatientModel} from "@models/patient.model";
import {UserModel} from "@models/user.model";

@Injectable({providedIn: 'root'})
export class AppointmentService {
  update = new Subject();

  constructor(private http: HttpClient) {
  }

  getAll(page = 1,
         perPage = 100,
         sort: string | null = null,
         status: any | null = null,
         type: any | null = null,
         patient: PatientModel | null = null,
         doctor: UserModel | null = null,
         location: any | null = null,
         notes: any | null = null,
         filterKeyword: string | null = null,
         date: any | null = null,
  ) {
    let params = new HttpParams()
      .set('page', page)
      .set('per_page', perPage);
    if (sort !== null) {
      params = params.set('sort', sort);
    }
    if (status !== null) {
      params = params.set('filter[status]', status.value);
    }
    if (date !== null) {
      params = params.set('filter[appointment_date]', date);
    }
    if (type !== null) {
      params = params.set('filter[type]', type.value);
    }
    if (patient !== null) {
      params = params.set('filter[patient_id]', patient.id);
    }
    if (location !== null) {
      params = params.set('filter[location]', location);
    }
    if (notes !== null) {
      params = params.set('filter[search]', notes);
    }
    if (doctor !== null) {
      params = params.set('filter[assigned_id]', doctor.id);
    }
    if (filterKeyword !== null) {
      params = params.set('filter[search]', filterKeyword);
    }
    return this.http.get<ResponseModel<AppointmentModel[]>>('admin/appointments', {params});
  }


  getAllAppointmentsForPatient(
    page = 1,
    perPage = 100,
    sort: string | null = null,
    status: any | null = null,
    type: any | null = null,
    patientId: any | null = null,
    doctor: UserModel | null = null,
    location: any | null = null,
    notes: any | null = null,
    filterKeyword: string | null = null,
  ) {
    let params = new HttpParams()
      .set('page', page)
      .set('per_page', perPage);
    if (sort !== null) {
      params = params.set('sort', sort);
    }
    if (status !== null) {
      params = params.set('filter[status]', status.value);
    }
    if (type !== null) {
      params = params.set('filter[type]', type.value);
    }
    if (patientId !== null) {
      params = params.set('filter[patient_id]', patientId);
    }
    if (location !== null) {
      params = params.set('filter[location]', location);
    }
    if (notes !== null) {
      params = params.set('filter[notes]', notes);
    }
    if (doctor !== null) {
      params = params.set('filter[doctor_id]', doctor.id);
    }
    if (filterKeyword !== null) {
      params = params.set('filter[search]', filterKeyword);
    }
    return this.http.get<ResponseModel<AppointmentModel[]>>('admin/appointments', {params});
  }


  addAppointment(appointment: any) {
    return this.http.post<ResponseModel<AppointmentModel>>('admin/appointments', appointment);
  }

  addAppointmentDocument(data: any, id: any) {
    return this.http.post<ResponseModel<AppointmentModel>>('admin/appointments/' + id.toString() + '/documents', data);
  }


  getAppointmentID(id: any) {
    return this.http.get<ResponseModel<AppointmentModel>>(`admin/appointments/${id}`);
  }

  getAppointmentPdf(
    id: any,
    signature: any,
    signatureP: any,
    signatureW: any,
    witnessName: any,
    relationToPatient: any
  ) {

    const body = {};
    if (signature != null) {
      body['doctor_signature'] = signature;
    }
    if (signatureP != null) {
      body['patient_signature'] = signatureP;
    }
    if (signatureW != null) {
      body['witness_signature'] = signatureW;
    }
    if (witnessName != null) {
      body['witness_name'] = witnessName;
    }
    if (relationToPatient != null) {
      body['relation_to_patient'] = relationToPatient;
    }
    return this.http.post<ResponseModel<any>>(`admin/appointments/${id}/consent-form`, body);
  }

  refusalForm(
    id: any,
    signatureDoc: any,
    signaturePat: any,
    signatureW: any,
    witnessName: any,
    relationToPatient: any,
    refusedItems: any,
  ) {

    const body = {};
    if (signatureDoc != null) {
      body['doctor_signature'] = signatureDoc;
    }
    if (signaturePat != null) {
      body['patient_signature'] = signaturePat;
    }
    if (signatureW != null) {
      body['witness_signature'] = signatureW;
    }
    if (witnessName != null) {
      body['witness_name'] = witnessName;
    }
    if (relationToPatient != null) {
      body['relation_to_patient'] = relationToPatient;
    }
    if (refusedItems != null) {
      body['refused_items'] = refusedItems;
    }
    return this.http.post<ResponseModel<any>>(`admin/appointments/${id}/refusal-form`, body);
  }


  medicalRecord(
    id: any,
    signatureDoc: any,
    provisionalDiagnosis: any,
    chiefComplaint: any,
    historyOfPresentingComplaint: any,
    onExamination: any,
    pastMedicalHistory: any,
    familyHistory: any,
    surgicalHistory: any,
    drugHistory: any,
    treatments: any,
  ) {

    const body = {};
    if (signatureDoc != null) {
      body['doctor_signature'] = signatureDoc;
    }
    if (treatments != null) {
      body['treatments_history'] = treatments;
    }
    if (drugHistory != null) {
      body['drug_history'] = drugHistory;
    }
    if (surgicalHistory != null) {
      body['surgical_history'] = surgicalHistory;
    }
    if (familyHistory != null) {
      body['family_history'] = familyHistory;
    }
    if (pastMedicalHistory != null) {
      body['past_medical_history'] = pastMedicalHistory;
    }
    if (onExamination != null) {
      body['on_examination'] = onExamination;
    }
    if (historyOfPresentingComplaint != null) {
      body['history_of_presenting_complaints'] = historyOfPresentingComplaint;
    }
    if (chiefComplaint != null) {
      body['chief_complaint'] = chiefComplaint;
    }
    if (provisionalDiagnosis != null) {
      body['provisional_diagnosis'] = provisionalDiagnosis;
    }
    return this.http.post<ResponseModel<any>>(`admin/appointments/${id}/medical-record-form`, body);
  }


  updateAppointment(id: any, appointment: any) {
    return this.http.post<ResponseModel<AppointmentModel>>(`admin/appointments/${id}?_method=PUT`, appointment);
  }

  updateAppointmentWithItems(id: any, appointment: any) {
    return this.http.put<ResponseModel<AppointmentModel>>(`admin/appointments/${id}`, appointment);
  }

  deleteAppointment(id: number) {
    return this.http.delete(`admin/appointments/${id}`);
  }

  deleteAppointmentDocument(appointmentId: any, documentUuid: any) {
    return this.http.delete(`admin/appointments/${appointmentId}/documents/${documentUuid}`);
  }

  deleteAppointmentItem(id: any) {
    return this.http.delete(`admin/appointment-items/${id}`);
  }

  sendUpdate(message: string) {
    this.update.next(message);
  }

  getUpdate() {
    return this.update.asObservable();
  }
}

import {PaginatorComponent} from "./components/paginator/paginator.component";
import {NgModule} from "@angular/core";
import {MatPaginatorModule} from "@angular/material/paginator";
import {ConfirmationDialogComponent} from "./components/confirmation-dialog/confirmation-dialog.component";
import {MatCardModule} from "@angular/material/card";
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {HttpClientModule} from '@angular/common/http';
import {NbCardModule} from "@nebular/theme";
import {SearchComponent} from "./components/search/search.component";
import {FormsModule} from "@angular/forms";
import {TypesPipe} from "@shared/pipes/types.pipe";
import {DateAndTimePipe} from "@shared/pipes/date-and-time-pipe";
import {DateFormatPipe} from "@shared/pipes/date-pipe";
import {CommonModule, NgClass, NgIf} from "@angular/common";
import {ReadMoreComponent} from "@shared/components/read-more/read-more.component";
import {StopPropagationDirective} from "@shared/directives/stop-propagation-directive";
import {TimeMaskDirective} from "@shared/directives/time-mask.directive";
import {SafePipe} from "@shared/pipes/safe-pipe";
import {WebcamSnapshotComponent} from './components/webcam-snapshot/webcam-snapshot.component';
import {SelectComponent} from "./components/select/select.component";
import { NgSelectModule } from "@ng-select/ng-select";
import {NotificationsComponent} from "@shared/components/notifications/notifications.component";
import {MatDividerModule} from "@angular/material/divider";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {MatTooltipModule} from "@angular/material/tooltip";
import {AppDetailsDialogComponent} from "@shared/components/app-details-dialog/app-details-dialog.component";
import {MatButtonModule} from "@angular/material/button";


const COMPONENTS = [
  PaginatorComponent,
  SelectComponent,
  ReadMoreComponent,
  ConfirmationDialogComponent,
  SearchComponent,
  NotificationsComponent,
  AppDetailsDialogComponent,
];
const PIPES = [
  TypesPipe,
  SafePipe,
  DateAndTimePipe,
  DateFormatPipe,
];
const MAT_MODULES = [
  MatPaginatorModule,
  MatDialogModule,
  MatDividerModule,
  MatButtonModule
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    StopPropagationDirective,
    TimeMaskDirective,
    WebcamSnapshotComponent

  ],
  providers: [
    {provide: MAT_DIALOG_DATA, useValue: {}},
  ],
  imports: [
    ...MAT_MODULES,
    MatCardModule,
    NgSelectModule,
    HttpClientModule,
    NbCardModule,
    NgIf,
    NgClass,
    FormsModule,
    NgbModule,
    MatTooltipModule,
    CommonModule,
  ],
  exports: [
    ...COMPONENTS,
    ...MAT_MODULES,
    ...PIPES,
    StopPropagationDirective,
    TimeMaskDirective

  ]
})


export class CoreModule {
}

import {Directive, HostListener} from '@angular/core';

@Directive({selector: '[appStopPropagation]'})

export class StopPropagationDirective {
    @HostListener('click', ['$event']) onClick($event: PointerEvent) {
        if (($event.target as HTMLInputElement).readOnly) {
            return;
        }
        $event.stopPropagation();
    }
}


<div class="card" [ngClass]="cardClass" [@cardRemove]="cardRemove" #toAnimate>
  <div class="card-header border-0" *ngIf="!hidHeader" [ngClass]="headerClass">
    <h5 *ngIf="!customHeader">{{ cardTitle }}</h5>
    <ng-content *ngIf="customHeader" select=".app-card-header"></ng-content>
  </div>
  <div [@collapsedCard]="collapsedCard">
    <div class="card-block" [ngClass]="blockClass">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="card-loader" *ngIf="loadCard">
    <i class="pct-loader1 anim-rotate"></i>
  </div>
</div>

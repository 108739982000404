import {Component} from '@angular/core';
import * as moment from 'moment';
import {SwUpdate} from '@angular/service-worker';
import {AppUpdateService} from "@services/app-update.service";
import {environment} from "@env";


@Component({
  selector: 'maedah-app-details-dialog',
  templateUrl: './app-details-dialog.component.html',
  styleUrls: ['./app-details-dialog.component.scss'],
})
export class AppDetailsDialogComponent {
  version_number: string;
  build_number: string;
  updated_at: any;
  appUpdateStatus = false;
  loading = false;

  constructor(private appUpdateService: AppUpdateService,
              private swUpdate: SwUpdate,
  ) {
    this.version_number = environment.version;
    this.build_number = environment.build;
    this.updated_at = this.fromNow(environment.updated_at);
  }

  ngOnInit() {
    this.appUpdateStatus = this.appUpdateService.isUpdateAvailable;
  }

  fromNow = (a: any) => {
    if (moment().diff(a, 'hours') > 24) {
      return `at ${a}`;
    }
    return moment(a).fromNow();
  };

  reload() {
    window.location.reload();
  }

  checkForUpdates() {
    if (this.swUpdate.isEnabled) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 3000);
      this.appUpdateService.updateChecker().subscribe(() => {
        this.appUpdateService.availableStatus.next(true);
        this.appUpdateStatus = true;
      });
    }
  }

  clearCache() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }
}

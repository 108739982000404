<nb-card accent="primary" class="mb-0">
  <nb-card-body class="overflow-hidden">
    <div class="p-0" mat-dialog-content>
      <p class="my-2 app-info"><strong>Version: </strong>{{version_number}}</p>
      <p class="my-2 app-info"><strong>Build: </strong>{{build_number}}</p>
      <mat-divider></mat-divider>
      <small class="d-block my-2 text-muted app-info">Updated {{updated_at}}</small>
    </div>
    <div class="pt-2 pb-2 d-flex flex-column gap-2 justify-content-center align-items-center border-top">
      <button (click)="clearCache()" mat-button color="warn" class="mt-2">Clear cache</button>
      <button (click)="checkForUpdates()" mat-button color="primary" class="blue">
        Check for updates
        <div *ngIf="loading" class="spinner-border spinner-border-sm text-light ml-2" role="status"></div>
      </button>
      <button (click)="reload()" *ngIf="appUpdateStatus" mat-button color="accent" class="primary">
        A newer version is available. Click to reload
      </button>
    </div>
  </nb-card-body>
</nb-card>

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {TokenModel} from "@models/token.model";
import {NgxPermissionsService} from "ngx-permissions";
import {LocalStorageRefService} from "./local-storage-ref.service";
import {ResponseModel} from "@models/response.model";
import {StorageKeysEnum} from "../enum/storage-keys.enum";
import {UserModel} from "@models/user.model";
import {PermissionModel} from "@models/permission.model";
import {FCMService} from "@services/fcm.service";
import {v4 as uuidv4} from 'uuid';
@Injectable({providedIn: 'root'})
export class AuthService {
  loginUpdate = new Subject<any>();
  token$: BehaviorSubject<TokenModel | null> =
    new BehaviorSubject<TokenModel | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private fcmService: FCMService,
    private permissionsService: NgxPermissionsService,
    private localStorageRefService: LocalStorageRefService,
  ) {
    this.token$.next(this.loadFromStorage());
  }

  public get currentUser() {
    return this.token$.value?.profile || null;
  }


  login(phone: string, password: string) {
    const data: {
      phone: string;
      password: string;
      udid?: string;
      fcm_token?: string;
    } = {
      phone: phone,
      password: password,
    };
    const fcmToken = this.fcmService.getFcmToken();
    if (fcmToken) {
      const uuid = uuidv4();
      data['udid'] = uuid;
      data['fcm_token'] = fcmToken;
    }
    return this.http.post<ResponseModel<TokenModel>>('auth/login', data);

  }

  logout() {
    const user = this.token$.value;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${user?.access_token}`,
    });
    this.http
      .get<ResponseModel<TokenModel>>(
        'auth/logout',
        {
          headers: headers,
        },
      )
      .subscribe();
    this.unAuth().then();
  }

  async unAuth() {
    this.localStorageRefService.removeData(StorageKeysEnum.USER);
    this.localStorageRefService.removeData(StorageKeysEnum.FCM_TOKEN);
    this.token$.next(null);
    await this.router.navigate(['/auth/login']);
  }

  isLoggedIn() {
    return this.token$.value !== null;
  }

  addAuthHeader(request: HttpRequest<any>, user: TokenModel): any {
    if (user && user.access_token) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${user.access_token}`,
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });
    }

    return request;
  }

  requestForgetPassword(email: string) {
    const body = {
      email: email,
    };
    return this.http.post<ResponseModel<UserModel>>('auth/request-forget-password', body);
  }

  requestOtp(id: any) {
    return this.http.post<ResponseModel<any>>('admin/users/' + id.toString() + '/request-otp', {});
  }

  verifyEmail(code: string) {
    const body = {
      otp_code: code,
    };
    return this.http.post<ResponseModel<TokenModel>>('auth/verify-otp/email', body).pipe(
      tap(async (response) => {
        this.handleAuthentication(response.data);
      }),
    );
  }


  forgetPassword(email: string, code: string, password: string) {
    const body = {
      email: email,
      code: code,
      password: password,
    };
    return this.http.post<ResponseModel<UserModel>>('auth/forget-password', body);
  }

  sendLoginUpdate(message: string) {
    this.loginUpdate.next(message);
  }

  getLoginUpdate() {
    return this.loginUpdate.asObservable();
  }

  loadPermissions(permissions: PermissionModel[]) {
    this.permissionsService.loadPermissions(
      permissions.map((permission) => permission.name),
    );
  }

  private loadFromStorage() {
    return JSON.parse(
      this.localStorageRefService.getData(StorageKeysEnum.USER),
    ) as TokenModel;
  }

  handleAuthentication(token: TokenModel) {
    this.token$.next(token);
    this.localStorageRefService.setData(
      StorageKeysEnum.USER,
      JSON.stringify(token),
    );
    this.loadPermissions(token.profile?.permissions || []);
  }

  handleAuthenticationAfterEditRoles(userModel: UserModel) {
    const tokenModel: TokenModel = new BehaviorSubject<TokenModel>(JSON.parse(localStorage.getItem(StorageKeysEnum.USER)!)).value as TokenModel;

    tokenModel.profile = userModel;

    this.token$.next(tokenModel);
    this.localStorageRefService.setData(
      StorageKeysEnum.USER,
      JSON.stringify(tokenModel),
    );
    this.loadPermissions(userModel?.permissions || []);
  }

  loadUserFromStorage(): UserModel | null {
    const userJson = this.localStorageRefService.getData(StorageKeysEnum.USER) ?? null;
    if (userJson != null && userJson != 'undefined' && userJson != undefined) {
      const tokenModel = JSON.parse(userJson) as TokenModel;
      return tokenModel.profile;
    }
    return null
  }
}

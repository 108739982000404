<div class="video-container py-3" *ngIf="!error">
  <video [class.show]="!isCaptured" [class.hide]="isCaptured" #video id="video" [width]="WIDTH" [height]="HEIGHT"
         autoplay></video>
  <canvas class="d-none" [class.show]="isCaptured" [class.hide]="!isCaptured" #canvas id="canvas" [width]="WIDTH"
          [height]="HEIGHT"></canvas>

  <img [height]="HEIGHT" style="object-fit: cover"
       [class.show]="isCaptured" width="400" [class.hide]="!isCaptured" src="{{image}}">
</div>

<div class="snap-container">
  <button class="btn btn-primary" *ngIf="!isCaptured" (click)="capture()">Snap Photo</button>
  <button class="btn btn-danger" *ngIf="isCaptured" (click)="removeCurrent()">Take Another</button>
  <button class="btn btn-success" *ngIf="isCaptured" (click)="submit()">Submit</button>

  <div *ngIf="error" class="text-danger mt-4">
    {{ error }}. Please try again
  </div>
</div>

<!--<ul class="list-unstyled">-->
<!--  <li *ngFor="let src of captures; let idx = index" (click)="setPhoto(idx)">-->
<!--    <img [src]="src" height="50" />-->
<!--  </li>-->
<!--</ul>-->

<div class="d-flex justify-content-center text-center align-items-center">
  <strong class="text-white">{{ user?.first_name }} {{ user?.last_name }}</strong>
  <ul class="navbar-nav">
    <li>
      <div class="dropdown drp-user" ngbDropdown placement="auto">
        <a style="color: white" href="javascript:" ngbDropdownToggle data-toggle="dropdown">
          <i class="icon feather icon-settings"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-end profile-notification"
             ngbDropdownMenu>
          <ul class="pro-body">
            <li>
              <a (click)="appVersion()" class="dropdown-item"><i class="feather icon-share"></i>App version</a>
            </li>
            <li>
              <a (click)="logout()" class="dropdown-item"><i class="feather icon-log-in"></i> Log out</a>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
</div>

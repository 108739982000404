
import {getMessaging, getToken} from 'firebase/messaging';
import {environment} from "@env";
import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class FCMService {
  message:any = null;
  private fcmToken: string='';
  constructor() {
  }
  requestPermission(): void {
      const messaging = getMessaging();
      getToken(messaging, {vapidKey: environment.firebase.vapidKey})
        .then((currentToken) => {
          if (currentToken) {
            this.fcmToken = currentToken;
          }
        });
  }
  getFcmToken(): string {
    return this.fcmToken;
  }
}

<nb-card>

  <nb-card-body class="p-2">
    <button (click)="updateStatus(item)"
            *ngFor="let item of statuses" type="button"
            class="btn  {{appointmentStatusEnum[item.value].toLowerCase()}}">
      {{appointmentStatusEnum[item.value]|formatType}}
    </button>

  </nb-card-body>
</nb-card>

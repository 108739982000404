import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {NotificationsModel} from "@models/notifications.model";
import {ResponseModel} from "@models/response.model";


@Injectable({
  providedIn: 'root',
})

export class NotificationsService {
  constructor(private http:HttpClient, private route:Router) { }
  show_all_notifications() {
    return this.http.get<ResponseModel<NotificationsModel[]>>('auth/notifications');
  }
  mark_all_as_read() {
      return this.http.post('auth/notifications/mark-all-as-read?_method=PUT', null);
  }
  notification_as_read(id:any) {
    return this.http.post(`auth/notifications/${id}/mark-as-read?_method=PUT`, null);
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NgxPermissionsService} from 'ngx-permissions';
import {AuthService} from "./auth.service";
import {TokenModel} from "@models/token.model";

@Injectable({providedIn: 'root'})
export class PermissionService {
  constructor(private permissionsService: NgxPermissionsService, private authService: AuthService) {
  }

  loadPermissions() {
    const currentUser: TokenModel = new BehaviorSubject<TokenModel>(JSON.parse(localStorage.getItem('user')!)).value as TokenModel;
    if (!this.authService.currentUser) {
      return;
    }
    const userPermissions = this.authService.currentUser.permissions;
    const Permissions = [];
    if (currentUser && userPermissions) {
      for (let i = 0; i < userPermissions.length; i++) {
        Permissions.push(userPermissions[i].name);
      }
    }
    this.permissionsService.loadPermissions(Permissions);
  }

  clearPermissions() {
    this.permissionsService.flushPermissions();
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';

interface PageData {
  pageSize: number;
  totalRows: number | undefined;
}

@Component({
  selector: 'app-paginator',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-paginator
        showFirstLastButtons
        hidePageSize
        [length]="pageData.totalRows"
        [pageSize]="pageData.pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="pageChanged($event)"
        aria-label="Select page"
        class="app-paginator"
    >
    </mat-paginator>
  `,
  styleUrls: ['./paginator.component.scss'],
})

export class PaginatorComponent implements OnChanges {
  pageSizeOptions: number[] = [1, 5, 10, 25, 50, 100];
  pageData: PageData = {
    pageSize: 50,
    totalRows: 0,
  };

  @Output() pageEvent = new EventEmitter<PageEvent>();
  @Input() total: number | undefined = 0;
  @Input() isTrip = false;
  @Input() stateData!: PageData;

  pageChanged(event: PageEvent) {
    this.pageEvent.emit({...this.pageData, ...event});
  }

  ngOnChanges(): void {
    this.pageData['totalRows'] = this.total;
    if (this.isTrip) {
      this.pageData = this.stateData;
    }
  }
}

import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {MethodsUtils} from "@shared/utils/methods.utils";
import {AppointmentStatusEnum} from "@shared/enum/appointment-status.enum";
import {first} from "rxjs";
import {AppointmentService} from "@services/appointment/appointment.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastService} from "@services/toast.service";
import {AppointmentModel} from "@models/appointment.model";
import {NbWindowRef} from "@nebular/theme";

@Component({
  selector: 'app-change-status-dialog',
  templateUrl: './change-status-dialog.component.html',
  styleUrls: ['./change-status-dialog.component.scss']
})
export class ChangeStatusDialogComponent implements AfterViewInit {

  statuses: any[] = [];

  @Input() appointmentID!: any;
  @Input() status!: any;

  appointmentStatusEnum = AppointmentStatusEnum;

  constructor(private methodsUtils: MethodsUtils,
              private toastService: ToastService,
              private windowRef: NbWindowRef,
              private appointmentService: AppointmentService,
              private spinnerService: NgxSpinnerService,) {
  }

  showNotification(title: string, message: string, type: any) {
    this.toastService.showToast(type, title, message);
  }

  closeForm(result: AppointmentModel) {
    this.windowRef.close(result);
  }

  updateStatus(newStatus: any) {
    this.spinnerService.show().then();
    const appointment = {
      status: Number(newStatus.value),
    }
    this.appointmentService.updateAppointment(this.appointmentID, appointment)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.spinnerService.hide().then();
          this.showNotification('Appointment ', 'Appointment Status updated successfully', 'success');
          this.appointmentService.sendUpdate('updated');
          this.closeForm(data.data);
        },
        error: (error) => {
          this.spinnerService.hide().then();
          this.showNotification(error.message, error.errors.toString(), 'error');
        },
      });


  }

  ngAfterViewInit(): void {
    this.statuses = this.methodsUtils.getStatuses(AppointmentStatusEnum);
    this.statuses = this.statuses.filter((status) => status.value != this.status.value);
  }

}

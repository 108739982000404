export const environment = {
  apiUrl: "https://api-dev.maedah.alebdaa.net/api/v1/",
  analyticsURL: "https://analytics.qomratech.com",
  version: '0.0.1',
  build: '1',
  updated_at: new Date(Date.now()).toUTCString(),
  production: false,
  firebase: {
    apiKey: "AIzaSyCvF5lwNB8rp4Xlp90NBCFtfOeLqnQ_3I8",
    authDomain: "maedah-project.firebaseapp.com",
    projectId: "maedah-project",
    storageBucket: "maedah-project.appspot.com",
    messagingSenderId: "844770791098",
    appId: "1:844770791098:web:0c04470a286f39c4effc83",
    measurementId: "G-Y5TWXBC66B",
    vapidKey: "BOWOnDgl2S5WnMnPIjDIT0Z4k087tkYGOlaizr6xTxeD9D-ptDoTs7Xj8EDUp7Cyr8h7LNcanzuiokbhXj8oalk",
  },
};

import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
})
export class ReadMoreComponent implements OnInit {
  @Input() content: string='';
  @Input() limit: number = 10;
  @Input() completeWords?: boolean;

  isContentToggled?: boolean;
  fixedLength?: boolean;
  nonEditedContent?: string;
  length =0;

  constructor() {
  }

  ngOnInit() {
    this.content = this.content.replace('null', ' ');
    this.nonEditedContent = this.content;
    // @ts-ignore
    this.content = this.formatContent(this.content);
  }

  toggleContent() {
    this.isContentToggled = !this.isContentToggled;
    // @ts-ignore
    this.content = this.isContentToggled ? this.nonEditedContent : this.formatContent(this.content);
  }

  formatContent(content: string) {
    if (this.completeWords) {
      this.fixedLength = (this.content.length <= this.limit);
    }

    return `${content.substr(0, this.limit)}`;
  }
  get textByTextContent() {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = this.content;
    return tmp.textContent || tmp.innerText || '';
  }
}

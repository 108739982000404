import {Injectable} from '@angular/core';
import moment from "moment";


@Injectable()

export class MethodsUtils {
  getFormatTime(time: any) {
    return moment(time).format('HH:mm') ?? time;
  }


  getStatuses(enumData: any, filter: any = null, exclude: string[] | null = null, cap: boolean = true) {
    let myEnum = [];
    const objectEnum = Object.keys(enumData);

    const values = objectEnum.slice(0, objectEnum.length / 2);
    const keys = objectEnum.slice(objectEnum.length / 2);

    for (let i = 0; i < objectEnum.length / 2; i++) {
      myEnum.push({
        title: cap ? this.capitalise(keys[i]) : keys[i],
        value: values[i],
        active: false,
      });
    }

    if (filter) {
      myEnum = myEnum.filter((item) => item.title.toLowerCase().includes(filter.slice(0, 5)));
    }

    if (exclude) {
      myEnum = myEnum.filter((item) => !exclude.includes(item.title.toLowerCase()));
    }

    return myEnum;
  }

  capitalise(value: any) {
    const removeUnderscore = value.split('_').join(' ');
    return removeUnderscore.charAt(0).toUpperCase() + removeUnderscore.slice(1).toLowerCase();
  }

  getValues(enumData: object, status: number) {
    const enumArray = this.getStatuses(enumData, null, ['none'], false);
    const bits = Object.keys(enumData).map(Number).filter(Boolean);
    const booleans = bits.map((b) => (status & b) === b);
    const indices = booleans.flatMap((b, i) => b ? i : []);
    indices.map((i) => enumArray[i].active = true);
    return enumArray;
  }

  getTitles(enumData: object) {
    const enumArray = this.getStatuses(enumData);
    const titles: string[] = [];
    enumArray.forEach((item) => titles.push(item.title));
    return titles;
  }
}

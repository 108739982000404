import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {filter, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})

export class AppUpdateService {
    availableStatus = new BehaviorSubject(false);

    constructor(private swUpdate: SwUpdate) {
    }

    get isUpdateAvailable() {
        return this.availableStatus.value;
    }

    updateChecker() {
        return this.swUpdate.versionUpdates.pipe(
            filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
            map((evt) => ({
                type: 'UPDATE_AVAILABLE',
                current: evt.currentVersion,
                available: evt.latestVersion,
            })));
    }
}

<nb-layout>
  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>
<ngx-spinner
  size="medium"
  [fullScreen]="true"
  color="#1adec4"
  bdColor="rgb(0 0 0 / 71%)"
  type="ball-scale-multiple">
</ngx-spinner>

import {Component, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';

import * as moment from 'moment';
import {NotificationsService} from "@services/notifications.service";
import {NotificationsModel} from "@models/notifications.model";
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit {
  message:any = null;
  notification_count:number=0;
  isBrowser:boolean=false;
  notifications:NotificationsModel[]=[];
  loading:boolean=true;
  created_at: string = '';
  formattedDate: string = '';
  notread:boolean=false;
  constructor(
      private notificationsService:NotificationsService,

      private datePipe: DatePipe) {
  }
  ngOnInit(): void {
   this.loadnotifications();
  }

  loadnotifications() {
    this.notificationsService.show_all_notifications().subscribe({
      next: (res)=>{
        this.notifications=res.data;
        this.notification_count=0;
        for(const notification of this.notifications) {
          if(notification.read==false) {
            this.notification_count+=1;
            this.notread= true;
          }
        }
        this.loading=false;
      },
      error: ()=>{
        this.loading=false;
      },
    });
  }

  formatLocalDate(dateString: string) {
    const utcMoment = moment.utc(dateString);
    const localMoment = utcMoment.local();
    return localMoment;
  }

  mark_as_read(id:any) {
    this.loading=true;
    this.notificationsService.notification_as_read(id).subscribe({
      next: ()=>{
        this.loadnotifications();
      },
      error: ()=>{
        this.loading=false;
      },
    });
  }

  mark_all_as_read() {
    this.loading=true;
    this.notificationsService.mark_all_as_read().subscribe({
      next: ()=>{
        this.loadnotifications();
      },
      error: ()=>{
        this.loading=false;
      },
    });
  }
}

import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import {NbWindowRef} from "@nebular/theme";

@Component({
  selector: 'app-webcam-snapshot',
  templateUrl: './webcam-snapshot.component.html',
  styleUrls: ['./webcam-snapshot.component.scss']
})
export class WebcamSnapshotComponent implements AfterViewInit {

  WIDTH = 500;
  HEIGHT = 300;

  image: any;
  stream: any;
  @ViewChild('video')
  public video: ElementRef;

  @ViewChild('canvas')
  public canvas: ElementRef;

  error: any;
  isCaptured: boolean;

  constructor(private spinnerService: NgxSpinnerService,
              private window: NbWindowRef,
  ) {
  }

  async ngAfterViewInit() {
    await this.setupDevices();
  }

  async setupDevices() {
    this.spinnerService.show().then();

    const constraints = {
      video: {facingMode: "environment"}
    };

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.stream = stream; // Save the stream to a property

        if (stream) {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = 'You have no output video device';
        }
        this.spinnerService.hide().then();

      } catch (e) {
        this.error = e;
      }
      this.spinnerService.hide().then();
    }
  }

  capture() {
    this.drawImageToCanvas(this.video.nativeElement);
    this.image = this.canvas.nativeElement.toDataURL('image/png');
    this.isCaptured = true;
  }

  removeCurrent() {
    this.isCaptured = false;
  }

  submit() {
    this.closeForm(this.image, true);
  }

  closeForm(image: any, success: boolean) {
    const res = {
      'image': image,
      'success': success,
    };
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.video.nativeElement.srcObject = null; // Clear the video source
    }
    this.window.close(res);
  }


  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext('2d')
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
  }

}
